import { useEffect, useRef, useState } from 'react';
import './App.scss';
import flowersline from './images/fl.png'
import gis from './images/gis.png'
import mfl1 from './images/mfl1.png'
import music from './audio/music.mp3'
function App() {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  function calculateTimeRemaining() {
    const deadline = new Date('2024-07-13 16:00');
    const currentTime = new Date();
    const timeDifference = deadline - currentTime;
    // console.log(currentTime);
    if (timeDifference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }
  const audioRef = useRef(null)
  const handleBodyClick = () => {
    // Проверяем, что аудио элемент не null и музыка еще не играет
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.play()
        .catch(err => console.error('Ошибка при попытке воспроизвести аудио:', err));
    }
  };



  useEffect(() => {
    
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
      console.log(timeRemaining.days, timeRemaining.hours, timeRemaining.minutes, timeRemaining.seconds);
    }, 1000);
  
    return () => {
      clearInterval(timerInterval);
    };
  }, []);
  return (
    <div className="App" onClick={handleBodyClick}>
      <audio ref={audioRef} src={music} preload='auto'>

      </audio>

      <div className='bgimg'>

      </div>
      <div className='box1 bg-photo'>

        <p className='gtext names'>
        Ұлан-Алан <br></br>1 Жас
        </p>
        {/* <p className='wtext box1text'>
          21 Шiлде 2024 жыл
        </p> */}
      <img src={flowersline} className='flowerline'></img>
      </div>
      <div className='box2'>
          <div className='box2-bg'>
          </div>
        <p className='text-box2'>
Құрметті ағайын-туыс, құда-жекжат,<br></br> дос-жарандар!
        </p>
        <p className='text-box2'> 
Сіздерді ұлдарымыз Ұлан мен Аланның <br/>1 жас тойына арналған салтанатты ақ дастарханымыздың қадірлі қонағы болуға шақырамыз!
        </p>

      </div>
      <div className='box3'>
      <img src={mfl1} className='mfl1'></img>
        <div className='daye'>
        <p className='calling'>
          Той салтанаты
        </p>
      <p className='dtt'>
      13.07.2024 жыл
        сағат 16:00-де
      </p>
      <table class="calendar">
        <caption>Шiлде 2024</caption>
        <thead>
            <tr>
                <th>Пн</th>
                <th>Вт</th>
                <th>Ср</th>
                <th>Чт</th>
                <th>Пт</th>
                <th>Сб</th>
                <th>Вс</th>
            </tr>
        </thead>
        <tbody>
            <tr>

                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
            </tr>
            <tr>

                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td class="special-day">13</td>
                <td>14</td>
            </tr>
            <tr>

                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
                <td>20</td>
                <td>21</td>
            </tr>
            <tr>

                <td>22</td>
                <td>23</td>
                <td>24</td>
                <td>25</td>
                <td>26</td>
                <td>27</td>
                <td>28</td>
            </tr>
            <tr>

                <td>29</td>
                <td>30</td>
                <td>31</td>
                <td style={{opacity:0.5}}>1</td>
                <td style={{opacity:0.5}}>2</td>
                <td style={{opacity:0.5}}>3</td>
                <td style={{opacity:0.5}}>4</td>
            </tr>
        </tbody>
    </table>
      </div>
      <div className='location'>
        <p className='location-text'>
        Астана қаласы,
        “Calypso” рестораны,
       Сығанақ 47
        </p>
        <a className='gislink' href='https://go.2gis.com/vj7kf'><img src={gis} className='gis'></img></a>
        
      </div>
      <div className='daytable'>
      </div> 
      <div className='timeout'>
      <p className='timeout-text'>
      Той салтанатына дейін
      </p>
              <div className="timer__items">
              <div className="timer__item timer__days"><p className='nc'>{timeRemaining.days}</p><br/><p lang='kk' className='date-named-word'>күн</p></div>
                <div className="timer__item timer__hours"><p className='nc'>{timeRemaining.hours}</p><br/><p lang='kk' className='date-named-word'>cағат</p></div>
                <div className="timer__item timer__minutes"><p className='nc'>{timeRemaining.minutes}</p><br/><p lang='kk' className='date-named-word'>минут</p></div>
                <div className="timer__item timer__seconds"><p className='nc'>{timeRemaining.seconds}</p><br/><p lang='kk' className='date-named-word'>секунд</p></div>

              </div>
      </div>
      </div>
      <div className='box4'>
        <div className='yelbox'>
        <p className='yelnames'>
      Той иелері:
      </p>
      <p className='yeltext'>
      Таңат-Раушан<br/>
      Жандос-Меруерт
      </p>
        </div>

      </div>
      <div className='box5'>

    <p className='dresss'> 
    Дресс код:
    Жігіттер қауымы костюм-шалбар. 
    Қыздар гардеробтағы ең сәнді кешкі көйлек

    </p>
      </div>
    </div>
  );
}

export default App;
